/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Card,
  Picklist,
  PicklistOption,
  Button,
  TimePicker,
  CheckboxToggle,
  ButtonIcon,
  Input,
} from 'react-rainbow-components'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { fi } from 'date-fns/locale'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowCircleLeft,
  faTimes,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import propTypes from 'prop-types'
import * as API from '../../utilities/api'
import './styles.scss'
import 'moment/locale/fi'

const OldEntriesList = ({ history }) => {
  const [dbResult, setDbResult] = useState({})
  const [queryUrl, setQueryUrl] = useState(null)
  const [filteredEvents, setFilteredEvents] = useState([])
  const [eventName, setEventName] = useState(null)
  const [date, setDate] = useState(new Date())
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [time, setTime] = useState(null)
  const [cardNumber, setCardNumber] = useState('')
  const [isDateRange, setIsDateRange] = useState(false)
  const [targetUnitEventNames, setTargetUnitEventNames] = useState([])
  moment.locale('fi')

  const clearFilters = () => {
    setIsDateRange(false)
    setQueryUrl(null)
    setDateRange([null, null])
    setDate(new Date())
  }

  const setDateRangeThisYear = () => {
    setIsDateRange(true)
    setDateRange([new Date(moment().startOf('year')), new Date(moment())])
  }

  const setDateRangeNextSixMonths = () => {
    setIsDateRange(true)
    setDateRange([new Date(moment()), new Date(moment().add(6, 'months'))])
  }

  useEffect(() => {
    let isMounted = true
    const getTargetUnitEvents = async () => {
      try {
        const res = await API.GET('api/v1/events/')
        if (isMounted) {
          if (res && res.data && res.data.results) {
            const { data } = res
            const events = data.results
            setTargetUnitEventNames([
              ...events,
              { id: 'Ei nimeä', name: 'Ei nimeä' },
            ])
          } else {
            setTargetUnitEventNames([])
          }
        }
      } catch (err) {
        if (isMounted) {
          setTargetUnitEventNames([])
        }
      }
    }
    getTargetUnitEvents()
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    let isMounted = true
    const getPreviousEvents = async (
      dateFilter,
      timeFilter,
      dateRangeFilter,
      cardNumberFilter,
      eventNameFilter
    ) => {
      let completeUrl = ''
      let res = null

      if (isDateRange && dateRangeFilter[0] && dateRangeFilter[1]) {
        // Filter based on date range
        const [start, end] = dateRangeFilter
        const formattedStart = moment(start).format('YYYY-MM-DD')
        const formattedEnd = moment(end).format('YYYY-MM-DD')
        completeUrl = `api/v1/entries/?rangeStart=${formattedStart}&rangeEnd=${formattedEnd}`
      } else {
        // Filter based on single date
        const formattedDate = moment(dateFilter).format('YYYY-MM-DD')
        completeUrl = `api/v1/entries/?eventDate=${formattedDate}`
      }

      if (timeFilter) {
        completeUrl += `&eventTime=${timeFilter}`
      }

      if (cardNumberFilter && cardNumberFilter !== '') {
        completeUrl += `&cardNumber=${cardNumberFilter}`
      }

      if (
        eventNameFilter &&
        eventNameFilter.value &&
        eventNameFilter.value.name
      ) {
        completeUrl += `&eventName=${eventNameFilter.value.name}`
      }
      try {
        res = await API.GET(completeUrl)
        if (isMounted) {
          if (res && res.data && res.data.results) {
            const { data } = res
            const { results } = data
            setDbResult(data)

            const events = results.details.map((item) =>
              !item.event_name ? { ...item, event_name: 'Ei nimeä' } : item
            )

            setFilteredEvents(events)
            setQueryUrl(null)
          } else {
            setQueryUrl(null)
          }
        }
      } catch (error) {
        if (isMounted) {
          setDbResult({})
          setFilteredEvents([])
          setQueryUrl(null)
        }
      }
    }
    getPreviousEvents(date, time, dateRange, cardNumber, eventName)
    return () => {
      isMounted = false
    }
  }, [date, time, isDateRange, dateRange, cardNumber, eventName])

  useEffect(() => {
    let isMounted = true
    const getPreviousEvents = async (url) => {
      const urlArr = url.split('//')
      urlArr.splice(0, 1)
      const newUrl = urlArr.join('')
      try {
        const res = await API.GET(`//${newUrl}`, {}, false)
        if (isMounted) {
          if (res && res.data && res.data.results) {
            const { data } = res
            const { results } = data
            setDbResult(data)

            const events = results.details.map((item) =>
              !item.eventName ? { ...item, eventName: 'Ei nimeä' } : item
            )

            setFilteredEvents(events)
          }
        }
      } catch (error) {
        if (isMounted) {
          setDbResult({})
          setFilteredEvents([])
        }
      }
    }
    if (queryUrl) {
      getPreviousEvents(queryUrl)
    }
    return () => {
      isMounted = false
    }
  }, [queryUrl])

  return (
    <main className="old-entries-container">
      <div className="filter-wrapper">
        <Button
          type="button"
          onClick={() => history.push('/operator')}
          variant="base"
        >
          <FontAwesomeIcon
            icon={faArrowCircleLeft}
            className="rainbow-m-right_medium"
          />
          Takaisin
        </Button>

        <div className="divider-container">
          <div className="horizontal-line" />
          <h2>Rajaa kirjauksia</h2>
          <div className="horizontal-line" />
        </div>

        <div style={{ width: '300px' }}>
          <Button type="button" onClick={clearFilters} variant="brand">
            Tänään
          </Button>
        </div>

        <div style={{ width: '300px' }}>
          <Button type="button" onClick={setDateRangeThisYear} variant="brand">
            Vuoden alusta lähtien
          </Button>
        </div>

        <div style={{ width: '300px' }}>
          <Button
            type="button"
            onClick={setDateRangeNextSixMonths}
            variant="brand"
          >
            Seuraavat 6kk
          </Button>
        </div>

        {!isDateRange && (
          <DatePicker
            name="Event date"
            id="eventDate"
            label="Tapahtuman päivämäärä"
            locale={fi}
            dateFormat="dd.MM.yyyy"
            onChange={(value) => setDate(value)}
            selected={date}
            value={new Date(date)}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            withPortal
          />
        )}
        {isDateRange && (
          <DatePicker
            id="eventDateRange"
            label="Tapahtumat päivämääräväliltä"
            placeholder="Alku- ja loppupäivämäärä"
            dateFormat="dd.MM.yyyy"
            startDate={startDate}
            endDate={endDate}
            selectsRange
            locale={fi}
            value={dateRange}
            onChange={(value) => setDateRange(value)}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            withPortal
          />
        )}

        <div style={{ padding: '5px' }}>
          <CheckboxToggle
            id="checkbox-toggle-component-1"
            label="Alku- ja loppupäivämäärä"
            value={isDateRange}
            onChange={() => setIsDateRange(!isDateRange)}
          />
        </div>
        <div style={{ position: 'relative', width: '100%', maxWidth: '300px' }}>
          <TimePicker
            name="Event time"
            id="eventTime"
            label="Tapahtuman kellonaika"
            value={time}
            onChange={(value) => setTime(value)}
            hour24
            style={{ maxWidth: '300px', width: '100%', height: '100%' }}
            cancelLabel="Peruuta"
          />
          <div style={{ position: 'absolute', top: '12px', right: '40px' }}>
            <ButtonIcon
              tooltip="Tyhjennä"
              icon={<FontAwesomeIcon icon={faTimes} />}
              onClick={() => setTime(null)}
            />
          </div>
        </div>

        <div style={{ position: 'relative', width: '100%', maxWidth: '300px' }}>
          <Input
            label="Kaikukortin numero"
            placeholder="Kirjoita numero"
            type="search"
            onChange={(e) => setCardNumber(e.target.value)}
            value={cardNumber}
            iconPosition="right"
            icon={
              <FontAwesomeIcon
                icon={faSearch}
                className="rainbow-color_gray-3"
              />
            }
          />
          <div style={{ position: 'absolute', top: '12px', right: '40px' }}>
            <ButtonIcon
              tooltip="Tyhjennä"
              icon={<FontAwesomeIcon icon={faTimes} />}
              onClick={() => setCardNumber('')}
            />
          </div>
        </div>

        <Picklist
          placeholder="Valitse"
          label="Tapahtuman nimi"
          style={{
            maxWidth: '300px',
            width: '100%',
            height: '100%',
          }}
          value={eventName && eventName.value}
          onChange={(value) => setEventName({ value })}
        >
          <PicklistOption
            name="header"
            label="Tapahtuman nimi"
            variant="header"
          />
          <PicklistOption name="all" label="Kaikki" />
          {targetUnitEventNames.map((item) => (
            <PicklistOption name={item.name} label={item.name} key={item.id} />
          ))}
        </Picklist>
      </div>
      <div className="divider-container">
        <div className="horizontal-line-wide" />
      </div>

      <h1>{`Kirjausten lkm: ${dbResult && dbResult.count}`}</h1>

      <h2>
        {`Aikuisia: ${
          dbResult &&
          dbResult.results &&
          dbResult.results.summary &&
          dbResult.results.summary.totalAdults.cardOwnerAmountSum
        }`}
      </h2>
      <h2>
        {`Lapsia: ${
          dbResult &&
          dbResult.results &&
          dbResult.results.summary &&
          dbResult.results.summary.totalChildren.childrenAmountSum
        }`}
      </h2>

      {filteredEvents.map((event) => (
        <div className="old-entries-list" key={event.id}>
          <Card
            title={`${event.cardNumber} : ${event.eventName}`}
            style={{ padding: '15px', textAlign: 'left' }}
          >
            <p>{`Päivämäärä: ${moment(event.eventDate).format(
              'DD.MM.yyyy'
            )}`}</p>
            <p>
              {`Kellonaika: ${
                event.eventTime ? event.eventTime : 'Ei tiedossa'
              }`}
            </p>
            <p>{`Lasten määrä: ${event.childrenAmount}`}</p>
          </Card>
        </div>
      ))}
      <div className="button-wrapper">
        {dbResult.previous && (
          <Button
            type="button"
            onClick={() => setQueryUrl(dbResult.previous)}
            variant="brand"
          >
            Edelliset
          </Button>
        )}
        {dbResult.next && (
          <Button
            type="button"
            onClick={() => setQueryUrl(dbResult.next)}
            variant="brand"
          >
            Seuraavat
          </Button>
        )}
      </div>
    </main>
  )
}

OldEntriesList.propTypes = {
  history: propTypes.instanceOf(Object).isRequired,
}

export default OldEntriesList
