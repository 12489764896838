/* eslint-disable func-names */
import axios from 'axios'

const baseUrl = process.env.REACT_APP_BASE_URL

const createRequest = (
  method,
  url,
  data = {},
  queryParams = {},
  useBaseUrl = true
) => {
  const urlPrefix = useBaseUrl ? baseUrl : ''
  const config = {
    method,
    url: urlPrefix + url,
    headers: {
      'Content-Type': 'application/json',
    },
    crossDomain: true,
    withCredentials: true,
    params: queryParams,
  }

  if (method === 'post') {
    config.data = data
  }

  return axios(config)
}

export const GET = (url, queryParams = {}, useBaseUrl = true) => {
  return createRequest('get', url, {}, queryParams, useBaseUrl)
}

export const DELETE = (url, queryParams = {}, useBaseUrl = true) => {
  return createRequest('delete', url, {}, queryParams, useBaseUrl)
}

export const POST = (url, body, useBaseUrl = true) => {
  return createRequest('post', url, body, {}, useBaseUrl)
}
