import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import propTypes from 'prop-types'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { Button, Input, ButtonIcon } from 'react-rainbow-components'
import CardScanner from '../Scanner'
import ErrorMessage from '../ErrorMessage'
import * as API from '../../utilities/api'
import './styles.scss'

const CultureOperatorView = ({
  handleSetCard,
  card,
  setErrors,
  errors,
  history,
  setInfoMessages,
  currentUserEmail,
  setUserData,
}) => {
  const [scan, setScan] = useState(false)
  const [cardNumber, setCardNumber] = useState(null)

  useEffect(() => {
    // Show card validation error message for 3 seconds
    const timer = setTimeout(() => {
      setErrors({})
    }, 3000)
    return () => clearTimeout(timer)
  }, [errors, setErrors])

  const cardNotExpired = (validUntil) => {
    if (!validUntil) return true
    const today = moment()
    const validDate = moment(validUntil)
    return today.isBefore(validDate)
  }

  const expireDateSoon = (validUntil) => {
    if (!validUntil) return false
    const today = moment()
    const validDate = moment(validUntil)
    return !validDate.isAfter(today.add(30, 'days'))
  }

  const handleCardResponse = (res) => {
    if (res && res.data && res.data.count === 1) {
      const { valid_until: validUntil, status } =
        res.data && res.data.results && res.data.results[0]
      const isValid = cardNotExpired(validUntil)
      const isExpiring = expireDateSoon(validUntil)
      const cardData = res.data.results[0]
      if (status !== 'active') {
        // Card has explicitly been set as not active,
        // and no entries are allowed
        setErrors({
          validation: 'Kortti ei ole aktiivinen!',
        })
      } else if (!isValid) {
        // Card has expired, but entries are still allowed.
        setErrors({
          expiration: `Kortin voimassaoloaika on päättynyt ${moment(
            validUntil
          ).format(
            'DD.MM.YYYY'
          )}, voit silti kirjata kortin käytön halutessasi.`,
        })
        handleSetCard(cardData)
      } else if (isValid && isExpiring) {
        // Card is valid but will expire soon. Entries are allowed.
        setErrors({
          expiration: `Kortin voimassaoloajan päättyminen lähestyy! Viimeinen voimassaolopäivä ${moment(
            validUntil
          ).format('DD.MM.YYYY')}`,
        })
        handleSetCard(cardData)
      } else {
        // All good
        handleSetCard(cardData)
      }
      if (isValid && !isExpiring) {
        // Card is valid. Show the expiring date. Entries are allowed.
        setInfoMessages({
          expiration: `Kortin viimeinen voimassaolopäivä on ${moment(
            validUntil
          ).format('DD.MM.YYYY')}`,
        })
      } else {
        setInfoMessages({
          expiration: null,
        })
      }
    } else if (res.status === 200) {
      // Request was ok but card was not found
      setErrors({ validation: 'Korttia ei löytynyt tietokannasta!' })
    } else {
      // Something went wrong like internal server error
      setErrors({ service: 'Palvelussa tilapäinen ongelma!!' })
    }
  }

  const removeSpaces = (str) => {
    const withoutSpaces = str.replace(/\s+/g, '')
    return withoutSpaces
  }

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      // const cardNumber = e.target.value
      const res = await API.GET(
        `api/v1/cards/?cardNumber=${removeSpaces(cardNumber)}`
      )
      handleCardResponse(res)
    }
  }

  const handleSubmit = async () => {
    if (cardNumber) {
      const res = await API.GET(
        `api/v1/cards/?cardNumber=${removeSpaces(cardNumber)}`
      )
      handleCardResponse(res)
    } else {
      setErrors({
        validation: 'Syötä kortin numero käsin tai lukemalla QR-koodi!',
      })
    }
  }

  const handleCardScan = async (number) => {
    const url = `api/v1/cards/?cardNumber=${removeSpaces(number)}`
    const res = await API.GET(url)
    handleCardResponse(res)
  }

  const handleLogout = () => {
    const res = API.DELETE('/api/v1/auth/sign_out')
    if (res) {
      setErrors({})
      setInfoMessages({})
      handleSetCard(null)
      setUserData({ isLoggedIn: false, userEmail: '' })
      history.push('/')
    } else {
      setErrors({
        service: 'Palvelussa tilapäinen ongelma!!',
      })
    }
  }

  return (
    <main className="culture-operator">
      {!card ? (
        <div className="culture-operator-form">
          <h1>{`Tervetuloa, ${currentUserEmail}!`}</h1>
          <div className="operator-buttons-wrapper">
            <Button
              onClick={handleLogout}
              type="button"
              variant="brand"
              label="Kirjaudu ulos"
            >
              Kirjaudu ulos
              <FontAwesomeIcon
                icon={faSignOutAlt}
                className="rainbow-m-left_medium"
              />
            </Button>

            <Button
              onClick={() => history.push('operator/entries')}
              type="button"
              variant="brand"
              label="Aiemmat kirjaukset"
            />
          </div>
          <div className="divider-container">
            <div className="horizontal-line" />
            <h2>Syötä kortin numero</h2>
            <div className="horizontal-line" />
          </div>
          <ErrorMessage msg={errors.validation} />
          <ErrorMessage msg={errors.service} />
          {!scan && (
            <p id="enter-card-number">
              Lue asiakkaan Kaikukortista qr-koodi ennen käynnin kirjaamista,
              tai näppäile koodi manuaalisesti.
            </p>
          )}
          {!scan && (
            <div className="manual-card-number">
              <div>
                <Input
                  type="text"
                  name="card-number-input"
                  title="card-number-input"
                  onKeyDown={(e) => handleKeyDown(e)}
                  onChange={(e) => setCardNumber(e.target.value)}
                  aria-labelledby="enter-card-number"
                />
                <ButtonIcon
                  size="large"
                  variant="brand"
                  onClick={handleSubmit}
                  icon={
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ fontSize: '40px' }}
                    />
                  }
                />
              </div>
            </div>
          )}
          {!scan && (
            <div className="divider-container">
              <div className="horizontal-line" />
              <h2>Tai</h2>
              <div className="horizontal-line" />
            </div>
          )}
          <div className="scan-button operator-buttons-wrapper">
            <Button
              onClick={() => setScan(!scan)}
              type="button"
              variant="brand"
              label={!scan ? 'Avaa skanneri' : 'Syötä käsin'}
            />
          </div>
          {scan && (
            <div className="card-scanner">
              <CardScanner
                title="Lue kortin QR-koodi"
                onRead={(value) => handleCardScan(value)}
              />
            </div>
          )}
        </div>
      ) : (
        <Redirect
          to={{
            pathname: '/operator/entry',
          }}
        />
      )}
    </main>
  )
}

CultureOperatorView.propTypes = {
  handleSetCard: propTypes.func.isRequired,
  errors: propTypes.instanceOf(Object).isRequired,
  setErrors: propTypes.func.isRequired,
  setInfoMessages: propTypes.func.isRequired,
  card: propTypes.shape({
    id: propTypes.number,
    card_number: propTypes.string,
  }),
  history: propTypes.instanceOf(Object).isRequired,
  currentUserEmail: propTypes.string.isRequired,
  setUserData: propTypes.func.isRequired,
}

CultureOperatorView.defaultProps = {
  card: null,
}

export default CultureOperatorView
