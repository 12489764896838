import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types'
import * as API from '../../utilities/api'
import EntryForm from '../EntryForm'
import ErrorMessage from '../ErrorMessage'
import InfoMessage from '../InfoMessage'

import './styles.scss'

const Entry = ({
  history,
  card,
  handleSetCard,
  errors,
  setErrors,
  infoMessages,
}) => {
  const [targetUnitEvents, setTargetUnitEvents] = useState([])
  const [relatedTargetUnits, setRelatedTargetUnits] = useState([])

  useEffect(() => {
    const getTargetUnitEvents = async () => {
      try {
        const res = await API.GET('api/v1/events/')
        if (res && res.data && res.data.results) {
          const { data } = res
          const events = data.results
            .map((event) => ({
              value: event.id,
              label: event.name,
            }))
            .slice(-10)
            .reverse()
          setTargetUnitEvents(events)
        } else {
          setTargetUnitEvents([])
        }
      } catch (err) {
        setTargetUnitEvents([])
      }
    }
    getTargetUnitEvents()
  }, [])

  useEffect(() => {
    const getTargetUnits = async () => {
      try {
        const res = await API.GET('api/v1/target_units/')
        if (res && res.data && res.data.results) {
          const { data } = res
          const units = data.results
            .map((unit) => ({
              value: unit.id,
              label: unit.name,
            }))
            .slice(-10)
            .reverse()
          setRelatedTargetUnits(units)
        } else {
          setRelatedTargetUnits([])
        }
      } catch (err) {
        setRelatedTargetUnits([])
      }
    }
    getTargetUnits()
  }, [])

  const handleNavigateBack = () => {
    handleSetCard(null)
    setErrors({ ...errors, validation: null, expiration: null })
    history.push('/operator')
  }

  return (
    <main className="entry">
      <ErrorMessage msg={errors.expiration} />
      <InfoMessage msg={infoMessages.expiration} />
      {relatedTargetUnits && relatedTargetUnits.length >= 1 && (
        <EntryForm
          redirect={() => history.push('/operator')}
          targetUnitEvents={targetUnitEvents}
          relatedTargetUnits={relatedTargetUnits}
          handleSetCard={handleSetCard}
          card={card}
          handleNavigateBack={handleNavigateBack}
          errors={errors}
          setErrors={setErrors}
        />
      )}
    </main>
  )
}

Entry.propTypes = {
  history: propTypes.instanceOf(Object).isRequired,
  handleSetCard: propTypes.func.isRequired,
  card: propTypes.shape({
    id: propTypes.number,
    card_number: propTypes.string,
  }),
  errors: propTypes.instanceOf(Object).isRequired,
  setErrors: propTypes.func.isRequired,
  infoMessages: propTypes.instanceOf(Object).isRequired,
}

Entry.defaultProps = {
  card: null,
}

export default withRouter(Entry)
